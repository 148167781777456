import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XMarkIcon, SparklesIcon } from "@heroicons/react/24/outline";

export default function Modal({
  open,
  title,
  message,
  setClose,
  type,
  icon,
  buttonText,
  runDetails, 
  runErrorDetails,
  confirmText,
  confirmAction,
}) {


  const errorDefinitions = {
    save_error:
      "Couldn't save your line items to the deal - this is usually because the data isn't mapped correctly to your HubSpot line item properties.",
    filter_failed: "Didn't match the filters for this template.",
    over_limit: "You've reached the maximum number of runs for this billing period.",
    template_inactive: "The template selected is currently set to inactive.",
    no_match: "This deal did not match the filters set in the template.",
  };
  

  if (type === "success") {
    icon = (
      <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
        <CheckIcon className="w-6 h-6 text-green-600" aria-hidden="true" />
      </div>
    );
  }
  if (type === "upgrade") {
    icon = (
      <div className="flex items-center justify-center w-12 h-12 mx-auto rounded-full bg-amber-500">
        <SparklesIcon className="w-6 h-6 text-white" aria-hidden="true" />
      </div>
    );
    message = (
      <>
        <p>The following feature is not available in your current plan:</p>
        <p className="mt-2">{message}</p>
      </>
    );
  }

  if (type === "error") {
    icon = (
      <div className="flex items-center justify-center w-12 h-12 mx-auto bg-red-100 rounded-full">
        <XMarkIcon className="w-6 h-6 text-red-600" aria-hidden="true" />
      </div>
    );
    message = (
      <>
        <p>{message}</p>
        <p className="mt-2">
          If the issue continues, reach out to our{" "}
          <a href="https://www.linepilot.co">support team</a>
        </p>
      </>
    );
  }

  return (
    <Transition.Root show={open !== false} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <div className="w-full">
                  {icon}
                  <div className="flex flex-col gap-2 mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h2" className="mb-2">
                      {title}
                    </Dialog.Title>

                    {runDetails?.createdCount > 0 && (
                      <p>
                        {runDetails.createdCount}{" "}
                        {runDetails?.createdCount > 1 ? "line items were" : "line item was"} created
                      </p>
                    )}
                    {runDetails?.updatedCount > 0 && (
                      <p>
                        {runDetails.updatedCount}{" "}
                        {runDetails?.updatedCount > 1 ? "line items were" : "line item was"} updated
                      </p>
                    )}
                    {runDetails?.updatedTotal && <p>Deal total is now {runDetails.updatedTotal}</p>}

                    {runErrorDetails && (
                      <>
                        <p className="text-sm">{errorDefinitions[runErrorDetails.errorCode]}</p>
                        {runErrorDetails.errorDetails && (
                          <p className="text-xs">Details: {runErrorDetails.errorDetails}</p>
                        )}
                      </>
                    )}

                    {message && <div className="text-sm">{message}</div>}
                  </div>
                </div>
                <div className="w-full mt-5 text-center sm:mt-6">
                  {confirmText ? (
                    <div className="flex gap-5 mx-auto w-fit">
                      <button type="button" className="button-text" tabIndex="1" onClick={setClose}>
                        {buttonText || "Cancel"}
                      </button>
                      <button
                        type="button"
                        className="button-primary"
                        tabIndex="2"
                        onClick={confirmAction}
                      >
                        {confirmText}
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="button-primary"
                      tabIndex="1"
                      onClick={() => setClose()}
                    >
                      {buttonText || "Close"}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
