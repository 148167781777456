import React from "react";
import { Link, navigate } from "@reach/router";
import Session from "../components/Session";
import LoadingState from "../components/states/LoadingState";
import fetchFromApi from "../utils/fetchFromApi";
import BlankWrapper from "../wrappers/BlankWrapper";
import Config from "../config";
import ErrorState from "../components/states/ErrorState";
import Message from "../components/modals/Message";
import Modal from "../components/modals/Modal";

class AcceptInvite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      error: false,
      success: false,
      loading: false
    };
  }

  componentDidMount = async () => {
    document.title = `Accept Invite ${Config.titleSuffix}`;
    await this.getStatus();
    const user = await Session.getUserData();
    this.setState({ pageLoading: false, user });
  };

  async getStatus() {
    const path = "/admin/organisation/get?invited=true";

    try {
      const response = await fetchFromApi(path, this.props.location.pathname);
      this.setState({ ...response });
    } catch (error) {
      this.setState({
        pageLoading: false,
        error: true,
        errorDetails: {
          title: "Could not get organisation",
          message:
            typeof error === "string"
              ? error
              : "We were unable to retrieve the organisation. Please try again.",
        },
      });
    }
  }

  acceptInvite = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const path = "/auth/accept-invite";
    const options = { method: "POST" };

    try {
      const response = await fetchFromApi(path, this.props.location.pathname, options);

      console.log('no errors, setting session');
      Session.setUserData(response);

      const navigateTo = response.user.org.onboarding !== "complete" ? "/onboarding" : "/";
      navigate(navigateTo);
    } catch (error) {
      this.setState({
        loading: false,
        error: true,
        errorDetails: {
          title: "Could not accept invite",
          message:
            typeof error === "string"
              ? error
              : "We were unable to accept your invite. Please try again.",
        },
      });
    }
  }

  render() {
    if (this.state.pageLoading) {
      return <LoadingState />
    }

    const alreadyInOrg = this.state.users && this.state.users.find(u => u.id == this.state.user.id);

    if (alreadyInOrg) {
      return <ErrorState
        h1="Error"
        message="You are already part of this organisation"
        continueUrl = "/choose-organisation"
        fullPage={false}
      />
    }

    return (
      <>
        <Message open={this.state.loading} type="loading" title="Accepting invite..." />
        <Modal
          open={this.state?.error}
          type="error"
          title={this.state.errorDetails?.title}
          message={this.state.errorDetails?.message}
          setClose={() => {
            this.setState({ error: false }),
              setTimeout(() => this.setState({ errorDetails: false }), 500);
          }}
        />
        <BlankWrapper hideNav={true}>
          <div className="flex flex-row items-center justify-center max-w-screen-xl min-h-screen mx-auto">
            <div className="flex flex-col w-full max-w-sm">
              <h2 className="text-2xl text-center">Accept Invitation to {this.state.org.name}?</h2>
              <p className="mb-4 font-light text-center">
                You will be able to manage preferences, connections and billing for{" "}
                {this.state.org.name}'s Line Pilot account.
              </p>
              <div className="flex gap-5">
                <Link type="button" to="/" className="button-secondary">
                  Don't Accept
                </Link>
                <button className="button-primary" onClick={this.acceptInvite}>
                  Accept Invite
                </button>
              </div>
            </div>
          </div>
        </BlankWrapper>
      </>
    );
  }
}

export default AcceptInvite;
