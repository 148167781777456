export default function PasswordMeter({ passwordScore }) {
  const getClasses = (i) => {
    const baseClass = "rounded-xl transition-colors h-2 ";

    if (i <= passwordScore && passwordScore <= 2) {
      return `${baseClass} h-2 bg-red-400`;
    } else if (i <= passwordScore && passwordScore <= 4) {
      return `${baseClass} h-2 bg-yellow-400`;
    } else if (i <= passwordScore && passwordScore === 5) {
      return `${baseClass} h-2 bg-green-500`;
    } else {
      return `${baseClass} h-2 bg-white`;
    }
  }

  return (
    [1, 2, 3, 4, 5].map((i) => (
      <div className="w-1/5 px-1" key={i}>
        <div className={getClasses(i)}></div>
      </div>
    ))
  );
}
