import Config from "../config";
import Session from "../components/Session";

async function fetchFromApi(path, redirect = "/", options = {}) {
  const url = `${Config.api.root}${path}`;
  
  try {
    const response = await fetch(url, {
      ...Config.api.options,
      ...options,
    });

    if (response.status !== 200) {
      throw response.status;
    }

    const json = await response.json();

    if (json.error) {
      throw json.error;
    }

    return json;
  } catch (error) {
    if (error === 403) {
      await Session.logout(redirect);
      return;
    }

    throw error;
  }
}

export default fetchFromApi;