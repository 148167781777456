import React from 'react';

const OptionButton = ({ icon, label, description, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="relative flex-1 min-h-full px-6 pt-4 pb-4 overflow-hidden text-left bg-white border rounded-lg hover:shadow-sm hover:border-primary active:ring-2 active:ring-primary focus:outline-none"
    >
      <span className="flex flex-1">
        <span className="flex flex-col flex-1">
          <span className="flex items-center mb-2 font-medium text-md text-secondary">
            {icon && <span className="mr-2 -ml-2">{icon}</span>}
            <span className="flex-1 leading-tight">{label}</span>
          </span>

          {description && (
            <span className="flex flex-col gap-1 mt-1 text-sm text-gray-500">{description}</span>
          )}
        </span>
      </span>
    </button>
  );
};

export default OptionButton;