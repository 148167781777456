import React, { useState, useRef } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { Link } from "@reach/router";
import classNames from "../../utils/classnames";

export default function OptionField({
  checked,
  label,
  description,
  disabled = false,
  proMessage = false,
  upgradeMessage = false,
  icon = false,
  tooltipImage = false,
  connectingLine = false,
  onChange
}) {
  const uniqueId = `custom-checkbox-${Math.random().toString(36).substr(2, 9)}`;
  const labelRef = useRef(null);

  // Use a custom hover function to make sure that it's not possible to hover over the tooltip
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };


  return (
    <label
      ref={labelRef}
      htmlFor={uniqueId}
      className={classNames(
        checked ? "border-transparent" : "border-tone-500",
        !disabled && "cursor-pointer",
        "relative bg-white hover:shadow-sm  border-gray-100 border flex-1 rounded-xl px-4 py-5 flex  group "
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <input
        type="checkbox"
        id={uniqueId}
        className="hidden"
        checked={checked}
        onChange={onChange}
      />

      <span className="flex-1 ">
        {disabled && (
          <svg
            class="z-10 absolute inset-0 bg-white opacity-50 h-full w-full stroke-tone-900/10 rounded-xl"
            fill="none"
          >
            <defs>
              <pattern
                id="pattern-d09edaee-fc6a-4f25-aca5-bf9f5f77e14a"
                x="0"
                y="0"
                width="10"
                height="10"
                patternUnits="userSpaceOnUse"
              >
                <path d="M-3 13 15-5M-5 5l18-18M-1 21 17 3"></path>
              </pattern>
            </defs>
            <rect
              stroke="none"
              fill="url(#pattern-d09edaee-fc6a-4f25-aca5-bf9f5f77e14a)"
              width="100%"
              height="100%"
            ></rect>
          </svg>
        )}
        <span className="flex flex-col flex-1 ">
          {icon && <span className="mr-2 -ml-2">{icon}</span>}
          <span className="flex-1 font-medium leading-tight">{label}</span>

          <CheckCircleIcon
            className={classNames(
              !checked ? "invisible" : "",
              " absolute top-1 right-1 h-6 w-6 text-primary"
            )}
            aria-hidden="true"
          />
        </span>
        <span
          className="flex flex-col gap-1 mt-3 text-sm font-normal leading-tight text-gray-500"
          dangerouslySetInnerHTML={{ __html: description }}
        ></span>
        {upgradeMessage && (
          <Link
            to="/billing"
            className="relative z-50 flex items-center w-0 gap-2 px-3 py-1 mt-2 text-sm text-white transition-colors rounded-full hover:text-white min-w-fit bg-amber-500 opacity-90"
          >
            <SparklesIcon className="w-5 h-5 -ml-1" />
            Upgrade to unlock
          </Link>
        )}
        {proMessage && (
          <span className="z-50 flex items-center w-0 gap-2 px-3 py-1 mt-2 text-sm text-white transition-colors rounded-full min-w-fit bg-amber-500 opacity-90">
            <SparklesIcon className="w-5 h-5 -ml-1" />
            Pro
          </span>
        )}
      </span>

      <span
        className={classNames(
          checked ? "border-primary ring-primary" : "border-transparent ring-transparent",
          "border absolute -inset-px rounded-xl ring-1 pointer-events-none"
        )}
        aria-hidden="true"
      />

      {connectingLine && checked && (
        <span className="top-full block absolute bg-primary w-[2px] h-full left-1/2 transform -translate-x-1/2"></span>
      )}

      <div
        className={classNames(
          hovered ? "visible opacity-100 h-auto" : "invisible opacity-0 h-0",
          "top-full mt-3 transition-all p-0 duration-300 absolute z-50 card w-[500px] flex flex-col shadow-md left-1/2 transform -translate-x-1/2"
        )}
        onMouseEnter={handleMouseLeave}
      >
        <div className="absolute top-0 w-0 h-0 transform -translate-x-1/2 -translate-y-full border-b-8 border-l-8 border-r-8 left-1/2 border-l-transparent border-r-transparent border-b-tone-500"></div>
        <div className="absolute top-[1px] w-0 h-0 transform -translate-x-1/2 -translate-y-full border-b-8 border-l-8 border-r-8 left-1/2 border-l-transparent border-r-transparent border-b-white"></div>

        <img src={tooltipImage} className="w-full h-auto p-2 bg-white rounded-lg" />
      </div>
    </label>
  );
}
