import React from "react";
import { Link } from "@reach/router";

import LoadingState from "../components/states/LoadingState";
import CreateOrganisation from "../components/forms/CreateOrganisation";
import OnboardingWrapper from "../wrappers/OnboardingWrapper";
import Session from "../components/Session";
import Config from "../config";
import fetchFromApi from "../utils/fetchFromApi";

class StartTrial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
    };
  }

  componentDidMount = async () => {
    document.title = `Start Trial ${Config.titleSuffix}`;

    this.updateOnboarding(0);
    const user = await Session.getUserData();
    const organisations = await this.getOrganisations();
    this.setState({ pageLoading: false, user, organisations });
  };

  async getOrganisations() {
    const path = "/admin/organisations/get";

    try {
      const response = await fetchFromApi(path, this.props.location.pathname);
      return response.organisations;
    } catch (error) {
      return false;
    }
  }

  updateOnboarding = (value) => {
    localStorage.setItem("isOnboarding", value);
    this.setState({ onboarding: value });
  };

  // Skip the survey if the user is part of another organisation that has already completed the survey.
  skipSurvey = () => {
    if (!this.state?.organisations?.length) {
      return false;
    }

    return this.state.organisations.some(o => o.survey_customerType !== null);
  }

  render() {
    const skipSurvey = this.skipSurvey();

    return (
      <>
        {this.state.pageLoading ? (
          <LoadingState />
        ) : (
          <OnboardingWrapper onboarding={0} skipSurvey={skipSurvey}>
            <div className="flex flex-col items-center max-w-md my-20 min-w-fill">
              <h2 className="text-center">Start a Trial</h2>
              <p className="my-6 text-center">
                Trying to extend or reactivate a trial? Please get in touch with our support team
                instead - they will be happy to help. <Link to="/">Go Back</Link>
              </p>
              <CreateOrganisation location={this.props.location} skipSurvey={skipSurvey} />
            </div>
          </OnboardingWrapper>
        )
      }
    </>
  )}
}

export default StartTrial;
