import { ReactComponent as LifeBuoyIcon } from "../../assets/images/icons8-lifebuoy.svg";

export default function OnboardingSidebar({ onboarding, updateOnboarding, skipSurvey }) {
  const steps = [
    "Register for a free account",
    "Tell us about you",
    "Connect to HubSpot",
    "Set up your first template",
    "Go live",
  ];
  const stepsList = steps.map((step, index) => {
    if (skipSurvey && index === 1) {
      return null
    }
    return (
    <li key={index} className="relative pb-3 list-none">
      {index + 1 < steps.length ? (
        <div
          className={
            (index < onboarding ? "bg-primary" : "bg-tone-500") +
            " -ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full"
          }
          aria-hidden="true"
        ></div>
      ) : null}

      <a
        href="#"
        onClick={index < onboarding && index > 0 ? () => updateOnboarding(index) : null}
        className={
          (index < onboarding && index > 0 ? "cursor-pointer" : "cursor-default") +
          " relative flex items-center group"
        }
      >
        {index < onboarding ? (
          <span className="flex items-center h-9">
            <span className="relative z-10 flex items-center justify-center w-8 h-8 rounded-full bg-primary group-hover:bg-primary">
              <svg
                className="w-5 h-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </span>
        ) : (
          <>
            {index === onboarding ? (
              <span className="flex items-center h-9" aria-hidden="true">
                <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-white border-2 rounded-full border-primary">
                  <span className="h-2.5 w-2.5 bg-primary rounded-full"></span>
                </span>
              </span>
            ) : (
              <span className="flex items-center h-9" aria-hidden="true">
                <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-white border-2 rounded-full border-tone-500 group-hover:border-putty">
                  <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-tone-500"></span>
                </span>
              </span>
            )}
          </>
        )}

        <span
          className={
            (index <= onboarding ? "text-primary " : " text-tone-900") +
            " flex flex-col min-w-0 ml-4"
          }
        >
          {step}
        </span>
      </a>
    </li>
  )});
  return (
    <div className="flex items-center h-screen max-w-screen-xl mx-auto">
      <div className="fixed items-center flex-1 hidden h-screen md:flex">
        <div className="card border-none p-0 pt-10 w-[24rem] flex flex-col gap-5">
          <h3 className="px-10">Get started with LinePilot in just a few minutes...</h3>
          <div className="px-12 mb-2">{stepsList}</div>
          <div className="flex items-center gap-5 px-5 pt-5 pb-5 border-t">
            <div>
              <LifeBuoyIcon className="w-auto h-12" />
            </div>
            <div>
              <h3 className="m-0">Need Help?</h3>
              <div className="gap-5 text-sm ">
                <a
                  href="mailto:support@weaveandblend.com"
                  className="text-sm text-primary hover:underline"
                >
                  Contact Support
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
