import React from "react";
import LPLogo from "../assets/images/LinePilot_logo.png";

const BlankWrapper = ({ children, hideNav = false }) => {
  // Your common template layout here
  return (
    <div className="w-full min-h-screen md:flex-row">
      <div className="fixed top-0 z-20 flex flex-row items-center w-full px-5 py-2">
        <a href="https://www.linepilot.co" className="relative block -left-5">
          <img src={LPLogo} className="w-auto h-20 md:h-24" alt="LinePilot Logo" />
        </a>
        {!hideNav && (
          <nav className="flex flex-row items-center justify-end flex-grow gap-2 text-sm">
            <a href="/login" className="button-secondary">
              Login
            </a>
            <a href="/register" className="button-primary">
              Sign Up Free
            </a>
          </nav>
        )}
      </div>
      <div className="relative z-10 w-full min-h-screen">{children}</div>
      <div className="fixed top-0 left-0 w-screen h-screen bg-blend"></div>
    </div>
  );
  }

export default BlankWrapper;
