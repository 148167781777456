import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  ArchiveBoxIcon,
  ArrowRightCircleIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  EllipsisVerticalIcon,
  XMarkIcon,
  HeartIcon,
  PencilSquareIcon,
  TrashIcon,
  UserPlusIcon,
} from "@heroicons/react/20/solid";
import classNames from "./../../utils/classnames";

export default function TemplateOptions({template, cloneTemplate, cloneDisabled, deleteTemplate}) {
  return (
    <Menu as="div" className="relative block h-full text-left">
      <div>
        <Menu.Button className="p-1 button-icon">
          <EllipsisVerticalIcon className="w-5 h-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 w-48 mt-2 origin-top-right bg-white divide-y rounded-md shadow-lg divide-pale ring-1 ring-tone-500 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? "bg-tone-400 text-tone-900" : "text-tone-900",
                    cloneDisabled ? "opacity-50 cursor-not-allowed" : "",
                    "group flex items-center px-4 py-2 text-sm w-full"
                  )}
                  disabled={cloneDisabled}
                  onClick={cloneTemplate}
                >
                  <DocumentDuplicateIcon
                    className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Clone
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? "bg-tone-400 text-red-400" : "text-red-400",
                    "group flex items-center px-4 py-2 text-sm w-full"
                  )}
                  onClick={deleteTemplate}
                >
                  <XMarkIcon className="w-5 h-5 mr-3" />
                  Delete
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}