
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useLocation } from "@reach/router";
import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  ExclamationTriangleIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import AsyncSelect from "react-select/async";
import Config from "../config";
import PreviewLineItem from "./PreviewLineItem";
import LoadingState from "./states/LoadingState";
import classNames from "./../utils/classnames";
import fetchFromApi from "../utils/fetchFromApi";

export default function Preview({
  open,
  setClose,
  updatedLines,
  isMatch,
  updatedCount,
  createdCount,
  lineItemPropertyDefinitions,
  getPreview,
  dealId,
  loading,
  stageName,
  hubspot,
  currency,
  runTemplate,
  showFilters
}) {
  const [selectedDeal, setSelectedDeal] = useState(dealId);
  const location = useLocation();

  let timeout = null;

  const doSearch = (query) => {
    return new Promise((resolve, reject) => {
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(async () => {
        if (query.length < 3) {
          resolve([]);
          return;
        }

        query = query.replace(/ /g, "%20");

        const path = `/deals/search?query=${query}`;
        try {
          const response = await fetchFromApi(path, location.pathname);
          resolve(response.deals);
        } catch (error) {
          reject(error);
        }
      }, 250); // Throttle calls
    });
  };

  const loadOptions = (query) =>
    new Promise(async (resolve) => {
      let results = await doSearch(query);
      resolve(results);
    });
  
  updatedLines =
    updatedLines &&
    updatedLines.sort((a, b) => {
      return parseInt(a?.hs_position_on_quote || 0) - parseInt(b?.hs_position_on_quote || 0);
    });
  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={setClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="w-screen max-w-2xl pointer-events-auto">
                  <form
                    className="flex flex-col h-full overflow-y-scroll bg-white shadow-md"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div className="flex-1">
                      {/* Header */}
                      <div className="px-4 py-6 bg-white sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-2">
                            <Dialog.Title className="mt-5 text-3xl text-dark">Preview</Dialog.Title>
                            <p className="text-sm">
                              Select a deal to preview the line items that LinePilot will create
                            </p>
                          </div>
                          <div className="flex items-center h-7">
                            <button
                              type="button"
                              className="button-icon"
                              onClick={() => setClose(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        {/* Project name */}
                        <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <h3 htmlFor="project-name" className="block ">
                              Search deals
                            </h3>
                          </div>
                          <div className="sm:col-span-2">
                            <AsyncSelect
                              loadingMessage={(e) =>
                                e.inputValue.length < 3 ? "Type at least 3 characters" : "Searching"
                              }
                              noOptionsMessage={(e) =>
                                e.inputValue.length < 3
                                  ? "Type at least 3 characters"
                                  : "Nothing found"
                              }
                              placeholder="Search for a deal"
                              cacheOptions={true}
                              defaultValue={selectedDeal}
                              getOptionLabel={(option) => option.properties.dealname}
                              isClearable
                              getOptionValue={(option) => option.id}
                              openMenuOnFocus="true"
                              loadOptions={loadOptions}
                              theme={Config.selectTheme}
                              onChange={(e) => {
                                setSelectedDeal(e);
                                getPreview(e?.id);
                              }}
                            />
                            {selectedDeal && (
                              <div className="flex gap-4 mt-1">
                                <a
                                  href={`https://${hubspot.domain}/contacts/${hubspot.hub_portal}/deal/${selectedDeal.id}`}
                                  target="_blank"
                                  className="inline-flex cursor-pointer items-center text-sm !font-medium underline text-primary !background-transparent"
                                >
                                  <ArrowTopRightOnSquareIcon
                                    className="w-3 h-3 mr-1"
                                    aria-hidden="true"
                                  />
                                  View in HubSpot
                                </a>
                                <a
                                  onClick={() => getPreview(selectedDeal.id)}
                                  className="inline-flex cursor-pointer items-center text-sm !font-medium underline text-primary !background-transparent"
                                >
                                  <ArrowPathIcon className="w-3 h-3 mr-1" aria-hidden="true" />
                                  Refresh Data
                                </a>
                                <button
                                  type="button"
                                  className="button-primary"
                                  onClick={() => runTemplate(selectedDeal.id)}
                                >
                                  Run Now
                                </button>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="flex flex-col p-4 space-y-2 ">
                          {loading && (
                            <>
                              <LoadingState />
                            </>
                          )}
                          {!loading && updatedLines && (
                            <>

                              {showFilters && (<>
                                {isMatch ? (
                                  <div className="p-4 m-4 border-l-4 border-green-400 bg-green-50">
                                    <div className="flex">
                                      <div className="flex-shrink-0">
                                        <CheckCircleIcon
                                          className="w-5 h-5 text-green-400"
                                          aria-hidden="true"
                                        />
                                      </div>
                                      <div className="ml-3">
                                        <p className="text-sm text-green-700">
                                          This deal matches the filters for this template and would
                                          have updated when triggered
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                
                                  <div className="p-4 m-4 border-l-4 border-yellow-400 bg-yellow-50">
                                    <div className="flex">
                                      <div className="flex-shrink-0">
                                        <ExclamationTriangleIcon
                                          className="w-5 h-5 text-yellow-400"
                                          aria-hidden="true"
                                        />
                                      </div>
                                      <div className="ml-3">
                                        <p className="text-sm text-yellow-700">
                                          This deal does not match the filters for this template and
                                          would not have updated when triggered
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>)}
                              {updatedLines.length > 0 && (
                                <div
                                  className={classNames(
                                    "p-4 m-4 border-l-4",
                                    createdCount === 0 && updatedCount === 0
                                      ? "border-yellow-400 bg-yellow-50 text-yellow-700"
                                      : "border-green-400 bg-green-50 text-green-700"
                                  )}
                                >
                                  <div className="flex">
                                    <div className="flex-shrink-0">
                                      {createdCount === 0 && updatedCount === 0 ? (
                                        <ExclamationTriangleIcon
                                          className="w-5 h-5 text-yellow-400"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <CheckCircleIcon
                                          className="w-5 h-5 text-green-400"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </div>
                                    <div className="ml-3">
                                      <p className="text-sm ">
                                        {createdCount} line item{createdCount !== 1 && "s"} would be
                                        created
                                      </p>
                                      <p className="text-sm">
                                        {updatedCount} line item{updatedCount !== 1 && "s"} would be
                                        updated
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <h3 className="block ">Line items</h3>
                              {updatedLines.map((line, i) => (
                                <PreviewLineItem
                                  lineItemPropertyDefinitions={lineItemPropertyDefinitions}
                                  line={line}
                                  currency={currency}
                                  hubspot={hubspot}
                                  key={`PreviewLineItem_${i}`}
                                />
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 px-4 py-5 border-t border-gray-200 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="button-text"
                          onClick={() => setClose(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
