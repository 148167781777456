import React from "react";
import FormModal from "../modals/FormModal";
import InviteForm from "../forms/InviteForm";
import classNames from "../../utils/classnames";

class InviteBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invite: false
    };
  }

  render() {
    return (<>
      <FormModal
          open={this.state.invite || false}
          setClose={(e) => this.setState({ invite: false })}
          icon={false}
          title="Invite User"
        >
          <InviteForm />
        </FormModal>

        <div
          className={classNames(
            "my-10 flex items-center w-full gap-10 card"
          )}
        >
          <div className="flex-1">
            <h3 className="m-0">{this.props.title}</h3>
            <p className="text-sm">
              Add team members to your account to help you get set up
            </p>
          </div>
          <div className="">
            <button
              onClick={(e) => this.setState({ invite: true })}
              className="button-secondary"
            >
              Invite User
            </button>
          </div>
        </div>

  </>);
  }
}

export default InviteBlock;