import React from "react";
import { navigate } from "@reach/router";
import CreateUser from "../components/forms/CreateUser";
import BlankWrapper from "../wrappers/BlankWrapper";
import Config from "../config";

class RegisterInvite extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => {
    document.title = `Register Invite ${Config.titleSuffix}`;
  };

  onSuccess = (response) => {
    const navigateTo = response.user.org.onboarding !== "complete" ? "/onboarding" : "/";
    navigate(navigateTo);
  }

  render() {
    return (
      <BlankWrapper hideNav={true}>
        <div className="flex flex-row items-center justify-center max-w-screen-xl min-h-screen mx-auto">
          <div className="flex flex-col w-full max-w-sm">
            <h2 className="mt-6 text-2xl text-center">Set up your account</h2>
            <CreateUser invite={true} location={this.props.location} onSuccess={this.onSuccess} />
          </div>
        </div>
      </BlankWrapper>
    );
  }
}

export default RegisterInvite;
